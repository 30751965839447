import React from 'react';

import Container from '../../sections/Container/Container';
import {
  EHorizontalCardV2AlignContent,
  HorizontalCardV2,
} from '../HorizontalCardV2';

export type THorizontalCardsV2Props = {
  cards: React.ComponentProps<typeof HorizontalCardV2>[];
  alignContent?: EHorizontalCardV2AlignContent;
  blockName?: string;
};

const HorizontalCardsV2: React.FC<THorizontalCardsV2Props> = props => {
  const { cards } = props;
  return (
    <Container>
      {cards.map((card, i) => (
        <HorizontalCardV2 key={i} {...card} />
      ))}
    </Container>
  );
};

export default HorizontalCardsV2;
