export enum EHorizontalCardV2Paddings {
  Yes = 'yes',
  No = 'no',
  OnlyTop = 'onlyTop',
}

export const enum EHorizontalCardV2Theme {
  HorizontalCard1 = 'horizontalCard1',
  HorizontalCard2 = 'horizontalCard2',
  HorizontalCard3 = 'horizontalCard3',
}

export const enum EHorizontalCardV2IconSize {
  XxL = '2XL',
  Xl = 'XL',
}

export const enum EHorizontalCardV2ImageHeight {
  L = '32',
  Xl = '56',
}

export const enum EHorizontalCardV2AlignContent {
  Left = 'left',
  Center = 'center',
}
