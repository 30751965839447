import {
  RichText,
  toImageProps,
  HorizontalCardsV2,
  EHorizontalCardV2Theme,
  HorizontalCardV2,
  EHorizontalCardV2Paddings,
  EHorizontalCardV2ImageHeight,
  EHorizontalCardV2AlignContent,
  TIconProps,
  isRichTextContentEmpty,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type THorizontalCardsV2ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'horizontal-cards-v2' }
>;

const HorizontalCardsV2Connected: React.FC<
  THorizontalCardsV2ConnectedProps
> = props => {
  const { cards = [] } = props;
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');
  const cardsConneted: React.ComponentProps<typeof HorizontalCardV2>[] =
    useMemo(() => {
      return cards.map(card => ({
        ...card,
        image: toImageProps(card.image),
        richTitle:
          card.richTitle && !isRichTextContentEmpty(card.richTitle) ? (
            <RichText content={card.richTitle} />
          ) : null,
        paddings: card.paddings as EHorizontalCardV2Paddings,
        imageHeight: card.imageHeight as EHorizontalCardV2ImageHeight,
        iconList: card.iconList?.map(item => toImageProps(item.icon)),
        iconSize: card.iconSize as TIconProps['size'],
        description:
          card.description && !isRichTextContentEmpty(card.description) ? (
            <RichText content={card.description} />
          ) : null,
        theme: card.theme as EHorizontalCardV2Theme,
        buttons: toCardButtons(card.buttons, currentLocale, appDispatch),
        alignContent: props.alignContent as EHorizontalCardV2AlignContent,
      }));
    }, [cards, currentLocale, appDispatch, props.alignContent]);

  return <HorizontalCardsV2 cards={cardsConneted} />;
};

export default HorizontalCardsV2Connected;
